import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccountApplicationFilterBar, LazyAccountApplicationItem, LazyAccountCompanyItem, LazyAccountOrderItem, LazyAccountVacancyFilterBar, LazyAccountVacancyItem, LazyAdminJobAlertItem, LazyAdminCreditTransactionItem, LazyBlockButtons, LazyBlockCallToAction, LazyBlockCollectionList, LazyBlockFaq, LazyBlockForm, LazyBlockHowItWorks, LazyBlockIubenda, LazyBlockPlans, LazyBlockSearchVacancies, LazyBlockSearchVacanciesLinks, LazyBlockSitemap, LazyBlockStatistics, LazyBlockText, LazyBlockTextMedia, LazyBlockUSPs, LazyBlockWorkAtSlider, LazyBlogIntro, LazyBlogItem, LazyCompanyFilterBar, LazyCompanyIntro, LazyCompanyItem, LazyMdcLinkComponent, LazySiteFilterBar, LazySiteIntro, LazySiteItem, LazySitePagination, LazyVacancyFilterBar, LazyVacancyIntro, LazyVacancyItem, LazyStrapiBlocksTextCodeNode, LazyStrapiBlocksTextImageNode, LazyStrapiBlocksTextParagraphNode, LazyStrapiBlocksTextQuoteNode, LazyStrapiBlocksTextHeading1Node, LazyStrapiBlocksTextHeading2Node, LazyStrapiBlocksTextHeading3Node, LazyStrapiBlocksTextHeading4Node, LazyStrapiBlocksTextHeading5Node, LazyStrapiBlocksTextHeading6Node, LazyStrapiBlocksTextBoldInlineNode, LazyStrapiBlocksTextCodeInlineNode, LazyStrapiBlocksTextItalicInlineNode, LazyStrapiBlocksTextLinkInlineNode, LazyStrapiBlocksTextListItemInlineNode, LazyStrapiBlocksTextStrikethroughInlineNode, LazyStrapiBlocksTextUnderlineInlineNode, LazyStrapiBlocksTextOrderedListNode, LazyStrapiBlocksTextUnorderedListNode, LazyProseA, LazyProseBlockquote, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseImg, LazyProseLi, LazyProseOl, LazyProseP, LazyProsePre, LazyProseScript, LazyProseStrong, LazyProseTable, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl, LazyStrapiBlocksText, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["AccountApplicationFilterBar", LazyAccountApplicationFilterBar],
["AccountApplicationItem", LazyAccountApplicationItem],
["AccountCompanyItem", LazyAccountCompanyItem],
["AccountOrderItem", LazyAccountOrderItem],
["AccountVacancyFilterBar", LazyAccountVacancyFilterBar],
["AccountVacancyItem", LazyAccountVacancyItem],
["AdminJobAlertItem", LazyAdminJobAlertItem],
["AdminCreditTransactionItem", LazyAdminCreditTransactionItem],
["BlockButtons", LazyBlockButtons],
["BlockCallToAction", LazyBlockCallToAction],
["BlockCollectionList", LazyBlockCollectionList],
["BlockFaq", LazyBlockFaq],
["BlockForm", LazyBlockForm],
["BlockHowItWorks", LazyBlockHowItWorks],
["BlockIubenda", LazyBlockIubenda],
["BlockPlans", LazyBlockPlans],
["BlockSearchVacancies", LazyBlockSearchVacancies],
["BlockSearchVacanciesLinks", LazyBlockSearchVacanciesLinks],
["BlockSitemap", LazyBlockSitemap],
["BlockStatistics", LazyBlockStatistics],
["BlockText", LazyBlockText],
["BlockTextMedia", LazyBlockTextMedia],
["BlockUSPs", LazyBlockUSPs],
["BlockWorkAtSlider", LazyBlockWorkAtSlider],
["BlogIntro", LazyBlogIntro],
["BlogItem", LazyBlogItem],
["CompanyFilterBar", LazyCompanyFilterBar],
["CompanyIntro", LazyCompanyIntro],
["CompanyItem", LazyCompanyItem],
["MdcLinkComponent", LazyMdcLinkComponent],
["SiteFilterBar", LazySiteFilterBar],
["SiteIntro", LazySiteIntro],
["SiteItem", LazySiteItem],
["SitePagination", LazySitePagination],
["VacancyFilterBar", LazyVacancyFilterBar],
["VacancyIntro", LazyVacancyIntro],
["VacancyItem", LazyVacancyItem],
["StrapiBlocksTextCodeNode", LazyStrapiBlocksTextCodeNode],
["StrapiBlocksTextImageNode", LazyStrapiBlocksTextImageNode],
["StrapiBlocksTextParagraphNode", LazyStrapiBlocksTextParagraphNode],
["StrapiBlocksTextQuoteNode", LazyStrapiBlocksTextQuoteNode],
["StrapiBlocksTextHeading1Node", LazyStrapiBlocksTextHeading1Node],
["StrapiBlocksTextHeading2Node", LazyStrapiBlocksTextHeading2Node],
["StrapiBlocksTextHeading3Node", LazyStrapiBlocksTextHeading3Node],
["StrapiBlocksTextHeading4Node", LazyStrapiBlocksTextHeading4Node],
["StrapiBlocksTextHeading5Node", LazyStrapiBlocksTextHeading5Node],
["StrapiBlocksTextHeading6Node", LazyStrapiBlocksTextHeading6Node],
["StrapiBlocksTextBoldInlineNode", LazyStrapiBlocksTextBoldInlineNode],
["StrapiBlocksTextCodeInlineNode", LazyStrapiBlocksTextCodeInlineNode],
["StrapiBlocksTextItalicInlineNode", LazyStrapiBlocksTextItalicInlineNode],
["StrapiBlocksTextLinkInlineNode", LazyStrapiBlocksTextLinkInlineNode],
["StrapiBlocksTextListItemInlineNode", LazyStrapiBlocksTextListItemInlineNode],
["StrapiBlocksTextStrikethroughInlineNode", LazyStrapiBlocksTextStrikethroughInlineNode],
["StrapiBlocksTextUnderlineInlineNode", LazyStrapiBlocksTextUnderlineInlineNode],
["StrapiBlocksTextOrderedListNode", LazyStrapiBlocksTextOrderedListNode],
["StrapiBlocksTextUnorderedListNode", LazyStrapiBlocksTextUnorderedListNode],
["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseImg", LazyProseImg],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTable", LazyProseTable],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
["StrapiBlocksText", LazyStrapiBlocksText],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
