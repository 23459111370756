import meierijstadLogo from "~/assets/images/logo-meierijstad.svg";
import maashorstLogo from "~/assets/images/logo-maashorst.svg";
import bernhezeLogo from "~/assets/images/logo-bernheze.svg";
import boxtelLogo from "~/assets/images/logo-boxtel.svg";
import ossLogo from "~/assets/images/logo-oss.svg";

import type { StrapiAttributes } from "@flyingkiwi/nuxt-components";
import type { Strapi4ResponseData } from "@nuxtjs/strapi";

export const useStaticData = () => {
  const { find } = useStrapi();
  const regionId = useState("regionId", () => 0);
  const regions = useState<Strapi4ResponseData<StrapiAttributes>[]>(
    "regions",
    () => [],
  );
  const places = useState<Strapi4ResponseData<StrapiAttributes>[]>(
    "places",
    () => [],
  );
  const companies = useState<Strapi4ResponseData<StrapiAttributes>[]>(
    "companies",
    () => [],
  );
  const educations = useState<Strapi4ResponseData<StrapiAttributes>[]>(
    "educations",
    () => [],
  );
  const employments = useState<Strapi4ResponseData<StrapiAttributes>[]>(
    "employments",
    () => [],
  );
  const professions = useState<Strapi4ResponseData<StrapiAttributes>[]>(
    "professions",
    () => [],
  );
  const hours = useState("hours", () => [
    { id: 1, from: 0, to: 8, label: "0 uur tot 8 uur" },
    { id: 2, from: 9, to: 16, label: "9 uur tot 16 uur" },
    { id: 3, from: 17, to: 24, label: "17 uur tot 24 uur" },
    { id: 4, from: 25, to: 36, label: "25 uur tot 36 uur" },
    { id: 5, from: 37, to: 40, label: "37 tot 40 uur" },
  ]);
  const fetchData = async () => {
    await callOnce(async () => {
      await Promise.all([
        //regions
        find<StrapiAttributes>("regions", {
          sort: "title:asc",
          pagination: {
            page: 1,
            pageSize: 9999,
          },
          populate: {
            logo: true,
            fallbackLogo: true,
          },
        }).then((r) => (regions.value = r.data)),
        //places
        find<StrapiAttributes>("places", {
          sort: "title:asc",
          populate: {
            region: true,
          },
          pagination: {
            page: 1,
            pageSize: 9999,
          },
        }).then((r) => (places.value = r.data)),
        //companies
        find<StrapiAttributes>("companies", {
          populate: {
            places: {
              region: true,
            },
          },
          sort: "title:asc",
          pagination: {
            page: 1,
            pageSize: 9999,
          },
        }).then((r) => (companies.value = r.data)),
        //educations
        find<StrapiAttributes>("educations", {
          sort: "sort:asc",
          pagination: {
            page: 1,
            pageSize: 9999,
          },
        }).then((r) => (educations.value = r.data)),
        //employments
        find<StrapiAttributes>("employments", {
          sort: "sort:asc",
          pagination: {
            page: 1,
            pageSize: 9999,
          },
        }).then((r) => (employments.value = r.data)),
        //professions
        find<StrapiAttributes>("professions", {
          sort: "title:asc",
          pagination: {
            page: 1,
            pageSize: 9999,
          },
        }).then((r) => (professions.value = r.data)),
      ]);
    });
  };

  //Computed values (uses the data fetched above)
  const regionPlaces = computed(() => {
    const p = places.value?.filter(
      (place: StrapiAttributes) =>
        place.attributes.region?.data?.id === region.value?.id,
    );
    return p;
  });

  const regionCompanies = computed(() => {
    return companies.value?.filter((company: StrapiAttributes) =>
      company.places?.data
        .map((p: StrapiAttributes) => p.attributes.regio.id)
        .flat()
        .includes(region.value?.id),
    );
  });

  const region = computed({
    get() {
      const region = regions.value?.find(
        (region) => region.id === regionId.value,
      );
      return region ?? regions.value?.[0];
    },
    set(value) {
      if (value) {
        regionId.value = value.id;
      }
    },
  });
  const logo = computed(() => {
    let l = meierijstadLogo;
    switch (region.value?.attributes.slug) {
      case "meierijstad":
        l = meierijstadLogo;
        break;
      case "maashorst":
        l = maashorstLogo;
        break;
      case "bernheze":
        l = bernhezeLogo;
        break;
      case "boxtel":
        l = boxtelLogo;
        break;
      case "oss":
        l = ossLogo;
        break;
    }
    return l;
  });
  const video = computed(() => {
    let vimeoId = 1005383395; //Werk in Meierijstad
    switch (region.value?.attributes.slug) {
      case "meierijstad":
        vimeoId = 1005383395;
        break;
      case "maashorst":
        vimeoId = 1005383356;
        break;
      case "bernheze":
        vimeoId = 1005383419; //Werk in Oss
        break;
      case "boxtel":
        vimeoId = 1005383395; //Werk in Meierijstad
        break;
      case "oss":
        vimeoId = 1005383419;
        break;
    }
    return vimeoId;
  });

  return {
    fetchData,
    regions,
    region,
    logo,
    video,
    places,
    regionPlaces,
    companies,
    regionCompanies,
    professions,
    educations,
    employments,
    hours,
  };
};
