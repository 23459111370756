<template>
  <NuxtLayout>
    <Title>{{ error.statusCode }} | {{ region.attributes?.title }}</Title>
    <div class="mx-auto mt-auto max-w-5xl text-center px-4 py-base-2">
      <div class="fk-prose mb-4">
        <h1>{{ $t("general.error.heading") }}</h1>
      </div>
      <div class="btn" @click="clear">
        {{ $t("general.error.toHome") }}
      </div>
    </div>
    <!-- {{ error }} -->
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";
const { region } = useStaticData();

defineProps<{
  error: NuxtError;
}>();
const clear = () => {
  clearError({
    redirect: "/vacatures",
  });
};
onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
