
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexkB84fTg3GeETGa_45CFlDEOonf4SHhor90Yqkm2o7VP5MMeta } from "/opt/render/project/src/frontend/pages/admin/index.vue?macro=true";
import { default as creditsr7cAWEQdvrGtAJA8q_45NY3UkigNB77N0iftq8Cidz3R8Meta } from "/opt/render/project/src/frontend/pages/admin/credits.vue?macro=true";
import { default as job_45alertJZCqnf2de5NDsZSmoPN9YNWg7NHw6xCvGtxyWhNHYtcMeta } from "/opt/render/project/src/frontend/pages/admin/job-alert.vue?macro=true";
import { default as adminOyrUQVQwqslrJhqkfJDpspZV1j_454EiflgUIOwAiWKrIMeta } from "/opt/render/project/src/frontend/pages/admin.vue?macro=true";
import { default as loginlz3x5K1PsHKGesV3c1Xyx5BjB9zYeeyJLo6Eqsomo2sMeta } from "/opt/render/project/src/frontend/pages/login.vue?macro=true";
import { default as _91_46_46_46slug_939MU4RLRnmxyCVWVJL8htuUiK5KRNPhj7OKzj3DrIDWcMeta } from "/opt/render/project/src/frontend/pages/[...slug].vue?macro=true";
import { default as indexw0HVjjrwIdAgpqNYLkqnzoRi_45amz6zaAyR58wF1InBIMeta } from "/opt/render/project/src/frontend/pages/my-account/index.vue?macro=true";
import { default as orderskiz95GBr_R3TpqokowGqeIFF_IchRWez2E8e_453_45NmC0Meta } from "/opt/render/project/src/frontend/pages/my-account/orders.vue?macro=true";
import { default as profileEOUvfAiRbiM_45_45O_q4l_F9GzKHHZ25CIY534Ekvk0kPAMeta } from "/opt/render/project/src/frontend/pages/my-account/profile.vue?macro=true";
import { default as companiesE4SXuOHVDF2vaDdRf5A_451A89s4jIEHYNQH4VENeXjqoMeta } from "/opt/render/project/src/frontend/pages/my-account/companies.vue?macro=true";
import { default as vacanciese4_E2FxvcaN8v3b6tBujSc4Hgd4LwjQJeb74vUc6rZMMeta } from "/opt/render/project/src/frontend/pages/my-account/vacancies.vue?macro=true";
import { default as applicationsW_zM9itvidi0PtSjzbCBpkhsiweT8KNAaO_458OKPH4x4Meta } from "/opt/render/project/src/frontend/pages/my-account/applications.vue?macro=true";
import { default as _91_91id_93_938OAQ7TkKciuSihdMlrXIb1ShcdAV9i2NdUStmVhxGXgMeta } from "/opt/render/project/src/frontend/pages/my-account/order/[[id]].vue?macro=true";
import { default as indexk8_24atvkK3cwhFiBQwd9MUo7LyOUlu6lQes6Gzx8kgMeta } from "/opt/render/project/src/frontend/pages/my-account/credits/index.vue?macro=true";
import { default as _91_91id_93_93Mo0ssasRHCBgyzlN049Eihwnz0B_45F9sJtpJogV8_45s_cMeta } from "/opt/render/project/src/frontend/pages/my-account/company/[[id]].vue?macro=true";
import { default as _91_91id_93_93ddaTeZqeF6bbNkryzpcZrih0zCMVhnAEwxcVO7koRnwMeta } from "/opt/render/project/src/frontend/pages/my-account/vacancy/[[id]].vue?macro=true";
import { default as history31ignU03vr5URsDjcntvHchNtNF57PALrFxcWWuBtwYMeta } from "/opt/render/project/src/frontend/pages/my-account/credits/history.vue?macro=true";
import { default as _91_91id_93_93KgW_kKEH7N89YNQW3iT0QSu761kwV3Fn4nhYlzJNqDAMeta } from "/opt/render/project/src/frontend/pages/my-account/application/[[id]].vue?macro=true";
import { default as my_45accountm1J65EtmaVgErwaLalfrCDhhufav3KMk4_45GTKNLD9Z0Meta } from "/opt/render/project/src/frontend/pages/my-account.vue?macro=true";
import { default as _91slug_93Fd2fH9lsIeME0hfE3rnxJxVja864uFTEyWpEUnVdGlEMeta } from "/opt/render/project/src/frontend/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93qaXfIl8RysT5hF7LCnHkmT2hyqy2DkcthT2_45HxVbgV0Meta } from "/opt/render/project/src/frontend/pages/place/[slug].vue?macro=true";
import { default as _91slug_93VcoCX7R_yX79gCqvpQeJf_45_xY_45_AKkdDcf7R3sdDCZcMeta } from "/opt/render/project/src/frontend/pages/company/[slug].vue?macro=true";
import { default as _91date_93D4LPebFRGA9vjze34fvOtS4d9vki9xU3sYuPUl2nf68Meta } from "/opt/render/project/src/frontend/pages/vacancy/[slug]/[date].vue?macro=true";
import { default as _91slug_93vyW2Wuv0uwkY0Ny2Dj5EiZ7n67oOg2GQklEqAWfIHuYMeta } from "/opt/render/project/src/frontend/pages/vacancy/[slug].vue?macro=true";
import { default as bestelling_45gelukt5MMDNp8eqnRhKgq029BnZrmVz5jDJ7_45c27QBesckQHkMeta } from "/opt/render/project/src/frontend/pages/bestelling-gelukt.vue?macro=true";
import { default as _91slug_93JlvbZ94maZJuF__0UPMbsAffqoQoc8rjRQWqMU9eU68Meta } from "/opt/render/project/src/frontend/pages/profession/[slug].vue?macro=true";
import { default as component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta } from "/opt/render/project/src/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw } from "/opt/render/project/src/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: adminOyrUQVQwqslrJhqkfJDpspZV1j_454EiflgUIOwAiWKrIMeta?.name,
    path: "/beheer",
    meta: adminOyrUQVQwqslrJhqkfJDpspZV1j_454EiflgUIOwAiWKrIMeta || {},
    component: () => import("/opt/render/project/src/frontend/pages/admin.vue"),
    children: [
  {
    name: "admin___nl",
    path: "",
    component: () => import("/opt/render/project/src/frontend/pages/admin/index.vue")
  },
  {
    name: "admin-credits___nl",
    path: "credits",
    component: () => import("/opt/render/project/src/frontend/pages/admin/credits.vue")
  },
  {
    name: "admin-job-alert___nl",
    path: "job-alert",
    component: () => import("/opt/render/project/src/frontend/pages/admin/job-alert.vue")
  }
]
  },
  {
    name: "login___nl",
    path: "/inloggen",
    component: () => import("/opt/render/project/src/frontend/pages/login.vue")
  },
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/opt/render/project/src/frontend/pages/[...slug].vue")
  },
  {
    name: my_45accountm1J65EtmaVgErwaLalfrCDhhufav3KMk4_45GTKNLD9Z0Meta?.name,
    path: "/mijn-account",
    meta: my_45accountm1J65EtmaVgErwaLalfrCDhhufav3KMk4_45GTKNLD9Z0Meta || {},
    component: () => import("/opt/render/project/src/frontend/pages/my-account.vue"),
    children: [
  {
    name: "my-account___nl",
    path: "",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/index.vue")
  },
  {
    name: "my-account-orders___nl",
    path: "orders",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/orders.vue")
  },
  {
    name: "my-account-profile___nl",
    path: "mijn-gegevens",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/profile.vue")
  },
  {
    name: "my-account-companies___nl",
    path: "bedrijfsgegevens-overzicht",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/companies.vue")
  },
  {
    name: "my-account-vacancies___nl",
    path: "vacatures-overzicht",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/vacancies.vue")
  },
  {
    name: "my-account-applications___nl",
    path: "sollicitaties-overzicht",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/applications.vue")
  },
  {
    name: "my-account-order-id___nl",
    path: "order/:id()",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/order/[[id]].vue")
  },
  {
    name: "my-account-credits___nl",
    path: "credits",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/credits/index.vue")
  },
  {
    name: "my-account-company-id___nl",
    path: "bedrijfsgegevens/:id?",
    meta: _91_91id_93_93Mo0ssasRHCBgyzlN049Eihwnz0B_45F9sJtpJogV8_45s_cMeta || {},
    component: () => import("/opt/render/project/src/frontend/pages/my-account/company/[[id]].vue")
  },
  {
    name: "my-account-vacancy-id___nl",
    path: "vacatures/:id?",
    meta: _91_91id_93_93ddaTeZqeF6bbNkryzpcZrih0zCMVhnAEwxcVO7koRnwMeta || {},
    component: () => import("/opt/render/project/src/frontend/pages/my-account/vacancy/[[id]].vue")
  },
  {
    name: "my-account-credits-history___nl",
    path: "credits/history",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/credits/history.vue")
  },
  {
    name: "my-account-application-id___nl",
    path: "sollicitaties/:id?",
    meta: _91_91id_93_93KgW_kKEH7N89YNQW3iT0QSu761kwV3Fn4nhYlzJNqDAMeta || {},
    component: () => import("/opt/render/project/src/frontend/pages/my-account/application/[[id]].vue")
  }
]
  },
  {
    name: "blog-slug___nl",
    path: "/blog/:slug()",
    component: () => import("/opt/render/project/src/frontend/pages/blog/[slug].vue")
  },
  {
    name: "place-slug___nl",
    path: "/vacatures-in/:slug()",
    component: () => import("/opt/render/project/src/frontend/pages/place/[slug].vue")
  },
  {
    name: "company-slug___nl",
    path: "/bedrijven/:slug()",
    component: () => import("/opt/render/project/src/frontend/pages/company/[slug].vue")
  },
  {
    name: "vacancy-slug___nl",
    path: "/vacatures/:slug()",
    component: () => import("/opt/render/project/src/frontend/pages/vacancy/[slug].vue"),
    children: [
  {
    name: "vacancy-slug-date___nl",
    path: ":date()",
    meta: _91date_93D4LPebFRGA9vjze34fvOtS4d9vki9xU3sYuPUl2nf68Meta || {},
    component: () => import("/opt/render/project/src/frontend/pages/vacancy/[slug]/[date].vue")
  }
]
  },
  {
    name: "bestelling-gelukt___nl",
    path: "/bestelling-gelukt",
    component: () => import("/opt/render/project/src/frontend/pages/bestelling-gelukt.vue")
  },
  {
    name: "profession-slug___nl",
    path: "/vacatures-in-beroepsgroep/:slug()",
    component: () => import("/opt/render/project/src/frontend/pages/profession/[slug].vue")
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/zoeken",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacature/:pathMatch(.*)",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/administratief-financieel",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/advies-consultancy",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/agrarisch-milieu",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/bouw",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/handel-detailhandel",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/horeca-recreatie",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/hr-recruitment",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/ict",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/industrie-productie",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/juridisch",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/klantenservice",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/kunst-cultuur-entertainment",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/management-beleid",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/marketing-communicatie",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/marketing-sales",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/milieu-natuur",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/onderwijs-kinderopvang",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/overheid",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/overige",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/schoonmaak-facilitair",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/sport-recreatie-toerisme",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/techniek",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/transport-logistiek",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/vacatures-in/zorg-gezondheid",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/bedrijvengids",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/werkgever",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/werkgever/:pathMatch(.*)",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/privacyverklaring",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  },
  {
    name: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEwMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub8AommxG8bzlt8yTh7K1CIfjb_45LyW4oqysB3gsEgQcEw
  }
]